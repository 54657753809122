/* SURA SANS */

@font-face {
    font-family: 'SuraSans-Extranegrita';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Extranegrita.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-ExtranegritaItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-ExtranegritaItalica.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-Fina';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Fina.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-FinaItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-FinaItalica.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-Ligera';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Ligera.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-LigeraItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-LigeraItalica.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-Negrita';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Negrita.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-NegritaItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-NegritaItalica.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-Regular';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Ligera.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-RegularItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-RegularItalica.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-Seminegrita';
    src: local('SuraSans'), url(./SuraSans/SuraSans-Seminegrita.otf) format('truetype');
}

@font-face {
    font-family: 'SuraSans-SeminegritaItalica';
    src: local('SuraSans'), url(./SuraSans/SuraSans-SeminegritaItalica.otf) format('truetype');
    font-weight: 600;
}
